import React from "react"
import Title from "./Title"
import ArtCard from "./ArtCard"
import styled from "styled-components"
import { RiBubbleChartLine } from "react-icons/ri"
import ThemeContext from "../context/ThemeContext"

const Artworks = ({ title, dataset }) => {
  return (
    <section className="section projects">
      <Title title={title} />
      <ThemeContext.Consumer>
        {theme => (
          <>
            <div className="section-center art-section">
              <ArtSectionTitle
                className={`${theme.language === "JP" ? "h3ArtJP" : ""}`}
              >
                <RiBubbleChartLine className="reactIconinText" />{" "}
                {theme.language == "EN"
                  ? "Creative Coding"
                  : "クリエイティブコーディング"}
              </ArtSectionTitle>
              <ArtCards className="my-panel-container">
                <ArtCard />
              </ArtCards>
            </div>
          </>
        )}
      </ThemeContext.Consumer>
    </section>
  )
}

const ArtCards = styled.div`
  max-width: 100%;
  margin: auto;
  @media screen and (min-width: 676px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 1rem;
  }
  @media screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const ArtSectionTitle = styled.h3`
  /* margin-left: 0.8em; */
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: -1vh;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--clr-primary-1);
  }
`

export default Artworks

import React from "react"
import { graphql } from "gatsby"

import Artworks from "../components/Artworks"
import SEO from "../components/Seo"
const artwork = () => {
  return (
    <>
      <SEO title="Kentaro Takahira" />
      <main>
        <section className="projects-page">
          <Artworks title="Artwork" />
        </section>
      </main>
    </>
  )
}

export default artwork
